import select from 'select-dom'
import on from 'dom-event'
import nanoajax from 'nanoajax'
import Tweezer from 'tweezer.js'

export const isMobile = () => window.innerWidth < 768

export const supportsObjectFit = () => {
  let objectFit = false
  for (const prop in document.documentElement.style) {
    if (/object(?:-f|F)it$/.test(prop)) {
      objectFit = true
      break
    }
  }
  return objectFit
}

export const isIE = () => {
  return navigator.userAgent.toLowerCase().indexOf('msie') > 0
}

export const isIEorEdge = () => {
  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

export const isFirefox = () => {
  return navigator.userAgent.toLowerCase().includes('firefox')
}

export const write = (el, value) => {
  el.innerHTML = value
}

export const set = (item, selector) => {
  if (item instanceof Array) {
    for (const i of item) {
      i.classList.add(selector)
    }
  } else {
    item.classList.add(selector)
  }
}

export const unset = (item, selector) => {
  if (item instanceof Array) {
    for (const i of item) {
      i.classList.remove(selector)
    }
  } else {
    item.classList.remove(selector)
  }
}

export const contains = (item, selector) => {
  return item.classList.contains(selector)
}

export const toggle = (item, selector) => {
  if (item instanceof Array) {
    for (const i of item) {
      i.classList.toggle(selector)
    }
  } else {
    item.classList.toggle(selector)
  }
}

export const getHeight = (el) => {
  return `${el.offsetHeight}px`
}

export const getWidth = (el) => {
  return `${el.offsetWidth}px`
}

export const isOver = (breakpoint) => {
  return window.innerWidth > breakpoint
}

export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const formatPrice = (num, fraction = 2) => {
  return (Number(num) / 100).toLocaleString('en-EN', {
    minimumFractionDigits: fraction,
  })
}

export const formatMoney = (cents, format = window.BARREL.moneyFormat) => {
  if (typeof cents === 'string') {
    cents = cents.replace('.', '')
  }
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/
  let formatString = format
  let value = ''

  const defaultOption = (opt, def) => {
    return typeof opt === 'undefined' ? def : opt
  }

  const formatWithDelimiters = (number, precision, thousands, decimal) => {
    precision = defaultOption(precision, 2)
    thousands = defaultOption(thousands, ',')
    decimal = defaultOption(decimal, '.')

    if (isNaN(number) || number == null) {
      return 0
    }

    number = (number / 100.0).toFixed(precision)

    const parts = number.split('.')
    const dollars = parts[0].replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      '$1' + thousands
    )
    const cents = parts[1] ? decimal + parts[1] : ''

    return dollars + cents
  }

  if (formatString.match(placeholderRegex)) {
    switch (formatString.match(placeholderRegex)[1]) {
      case 'amount':
        value = formatWithDelimiters(cents, 2)
        break
      case 'amount_no_decimals':
        value = formatWithDelimiters(cents, 0)
        break
      case 'amount_with_comma_separator':
        value = formatWithDelimiters(cents, 2, '.', ',')
        break
      case 'amount_no_decimals_with_comma_separator':
        value = formatWithDelimiters(cents, 0, '.', ',')
        break
    }
  } else {
    formatString = '$' + '{{amount}}'
    value = formatWithDelimiters(cents, 2)
  }

  return formatString.replace(placeholderRegex, value)
}

export const getUrlParam = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]') // eslint-disable-line
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(window.location.search)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const handleize = (str) => str.replace(/[ /_]/g, '-').toLowerCase()

/**
 * Decodes a string that has been encode through the 'url_encode' Shopify filter
 *
 * @param {*} str encoded string
 * @returns {string} decoded string
 */
export const decode = (str) => decodeURIComponent(str).replace(/\+/g, ' ')

/**
 * Generates a URL that is suitable for the Shopify
 * image CDN. Adds the current image size suffix.
 *
 * @param {string} src Image url
 * @param {*} size Image size
 * @returns {string} Sized image url
 */
export const getImageWithSize = (src = '', size = 1000) => {
  return ('' + src).replace(/([.](?:jpe?g|png))/, `_${size}x$1`)
}

/**
 * Used by components like the product card to select
 * the current product image based on the active color.
 * If there is no active color, a fallback image
 * should be returned if it is defined.
 *
 * @param {string} name color
 * @param {string} value value
 * @param {Array} images images to loop over
 * @param {boolean} featured featured image
 * @param {boolean} fallback fallback image
 * @returns {string} product image url
 */
export const getProductImage = (
  name = '',
  value = '',
  images,
  featured = false,
  fallback = false
) => {
  const key = `${name}-${(value || '').replace(/[/ ]/g, '-')}`.toLowerCase()
  const image = (images || []).find((i) => {
    return ~i.indexOf(key) && ~i.indexOf(featured ? 'pos-1' : 'pos-2')
  })
  if (!image) {
    return getImageWithSize(fallback, 600)
  }
  return getImageWithSize(image, featured ? 1200 : 600)
}

export const createArrayFromNumRange = (size, start) => {
  return Array.from(Array(size), (_, i) => start + i)
}

export const createMonthArray = () => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
}

export const docOffset = (el) => {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

/* window.Element.closest() polyfill for IE11 */
if (!window.Element.prototype.matches) {
  window.Element.prototype.matches =
    window.Element.prototype.msMatchesSelector ||
    window.Element.prototype.webkitMatchesSelector
}

if (!window.Element.prototype.closest) {
  window.Element.prototype.closest = function (s) {
    let el = this

    do {
      if (el.matches(s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

/* CustomEvent polyfill */
;(function () {
  if (typeof window.CustomEvent === 'function') return false

  /**
   * Custom event
   *
   * @param {string} event Event name
   * @param {object} params Event options
   * @returns {undefined}
   */
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
})()

/* Trap focus into a particular element */
// TODO: remove this implementation of trapFocus, leverage the one in @shopify/theme-a11y
export const trapFocus = (elem) => {
  const focusableEls = select.all(
    [
      'a[href]',
      'area[href]',
      'input:not([disabled])',
      'select:not([disabled])',
      'textarea:not([disabled])',
      'button:not([disabled])',
      'iframe',
      'object',
      'embed',
      '[contenteditable]',
      '[tabindex]:not([tabindex^="-"])',
    ],
    elem
  )

  if (focusableEls.length) {
    const firstFocusableEl = focusableEls[0]
    const lastFocusableEl = focusableEls[focusableEls.length - 1]

    // Redirect first Shift+Tab to the last focusable element
    on(firstFocusableEl, 'keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault()
        lastFocusableEl.focus()
      }
    })

    // Redirect last Tab to the first focusable element
    on(lastFocusableEl, 'keydown', (e) => {
      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault()
        firstFocusableEl.focus()
      }
    })
  }
}

const _getQueryPattern = (key) => new RegExp(`[?&]${key}=([^&]+)`)

export const getQueryVar = (key, url = window.location.href) => {
  const pattern = _getQueryPattern(key)
  const matches = url.match(pattern)
  return matches ? matches[1] : ''
}

export const removeQueryVar = (key, url = window.location.href) => {
  const idx = url.indexOf('?')

  if (idx === -1) {
    return url
  }

  const pattern = _getQueryPattern(key)
  const replaced = url.replace(pattern, '').replace(/\?$/, '')

  if (replaced.includes('&') && !replaced.includes('?')) {
    return replaced.replace(/&/, '?')
  }

  return replaced
}

export const addQueryVar = (key, value, url = window.location.href) => {
  const removed = removeQueryVar(key, url)
  if (!value && value !== 0) {
    return removed
  }
  return `${removed}${!removed.includes('?') ? '?' : '&'}${key}=${value}`
}

export const fetchData = (url) => {
  return new Promise((resolve, reject) => {
    nanoajax.ajax({ url }, (code, res) => {
      res = JSON.parse(res)
      resolve(res)
    })
  })
}

export const style = (item, property, value) => {
  if (item instanceof Array) {
    for (const i of item) {
      i.style[property] = value
    }
  } else {
    item.style[property] = value
  }
}

export const unstyle = (item, property) => {
  if (item instanceof Array) {
    for (const i of item) {
      i.style.removeProperty(property)
    }
  } else {
    item.style.removeProperty(property)
  }
}

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

export const removeElement = (el) => {
  el.parentNode.removeChild(el)
}

/**
 * Detect touch device
 *
 * @returns {boolean} is touch device
 */
export function isTouchDevice() {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        (window.DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof window.DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
    )
  )
}

export const filterObject = (obj, predicate) => {
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})
}

export const getOffset = (
  el,
  win = window,
  docElem = document.documentElement,
  box = false
) => {
  box = el.getBoundingClientRect()
  return (
    box.top + (win.pageYOffset || docElem.scrollTop) - (docElem.clientTop || 0)
  )
}

export const scrollTo = (
  el,
  offset = 0,
  duration = 1000,
  callback = () => {}
) => {
  new Tweezer({
    start: window.scrollY || window.pageYOffset,
    end: getOffset(el) - offset,
    duration,
  })
    .on('tick', (v) => window.scrollTo(0, v))
    .on('done', callback)
    .begin()
}

export const addEventListeners = (element, events, listener) => {
  events.split(' ').forEach((e) => {
    element.addEventListener(e, listener, false)
  })
}

/* eslint-disable */
export const forceRefreshYotpoWidgets = () => {
  try {
    if (window.Yotpo) {
      const api = new window.Yotpo.API(yotpo)
      api.refreshWidgets()
    }
  } catch (e) {
    // TODO: Ignore unexpected errors from Yotpo
  }
}
/**
 * Given a Storefront Id string, parse out the id
 *
 * @param {String} storefrontId
 * @returns {String} id
 */
export const parseStorefrontId = (storefrontId) => {
  return storefrontId.split('/')[4]
}

export const getVariantName = (variantName) => {
  if (isEmpty(variantName) || variantName === 'Default Title') return ''

  return variantName
}

export const getProductFullName = (productName, variantName) => {
  if (isEmpty(getVariantName(variantName))) return productName

  return `${productName} - ${variantName}`
}

export const mapProductCollectionsToGa4Categories = (collections) => {
  if (!collections || !collections.length) return {}

  const res = {}

  collections.slice(0, 5).forEach((collection, index) => {
    if (!collection) return

    const keyName = `item_category${index > 0 ? index + 1 : ''}`
    res[keyName] = collection
  })

  return res
}

export const objToArr = (obj) => {
  if (!obj) return []

  return Object.entries(obj).map(([key, value]) => ({ key, value }))
}

export const arrToObj = (arr) => {
  if (!arr || !arr.length) return {}

  return arr.reduce((res, { key, value }) => {
    const temp = {}
    temp[key] = value

    return { ...res, ...temp }
  }, {})
}

export const mapCartItemNodeToGa4Item = (
  product,
  quantity = 1,
  index = 0,
  listId = 'product_carousel',
  listName = 'Product Carousel'
) => {
  if (!product) return {}

  return {
    item_id: product.id,
    item_name: getProductFullName(product.title, product.variant_title),
    index,
    item_brand: product.vendor,
    item_list_id: listId,
    item_list_name: listName,
    item_variant: getVariantName(product.variant_title),
    price: formatPrice(product.price),
    quantity,
    ...mapProductCollectionsToGa4Categories(product.collection),
  }
}
/**
 * Check if a variable is of function type
 * @param {object} obj variable to check
 * @returns {boolean}
 */
export function isFunction (obj) {
  return typeof obj === 'function' || false
}

/**
 *  Converts a string in kebab case to camel case
 *
 * @param {String} string String in kebab case
 * @returns {String} String in camel case
 */
export function kebabToCamel (string) {
  return string.replace(/-([a-z])/g, (m, w) => {
    return w.toUpperCase()
  })
}

/**
 *  Converts a string in camel case to kebab case
 *
 * @param {String} string String in camel case
 * @returns {String} String in kebab case
 */
export function camelToKebab (str) {
  return str.split('').map((letter, idx) => {
    return letter.toUpperCase() === letter
      ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
      : letter
  }).join('')
}

export const getProductGiftMessage = (base, productTitle) => {
  if (isEmpty(base) || isEmpty(productTitle)) return

  return base.replaceAll('<<product_title>>', productTitle)
}